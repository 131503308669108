<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden px-3">
                        <div class="card-body">
                            <div v-if="!isLoad" class="col-12 py-3">
                                <form @submit.prevent="postSCR" class="row" style="z-index: 99999">
                                    <div class="col-12 form-group mb-3">
                                        <label for="">Smart Course Recommendation</label>
                                        <select v-model="input" class="form-control" @change="setScrCourse">
                                            <option value="" selected disabled>Select Course</option>
                                            <option :value="item.id" v-for="(item, index) in courses.filter(s => !scr.includes(s.id) && s.publish == true)" :key="index">{{ item.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-12 form-group mb-3">
                                        <div class="table-responsive mw-100 m-0 px-1">
                                            <table class="table table-hover table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th width="5%" class="text-center">#</th>
                                                        <th width="250px" class="text-center">Cover</th>
                                                        <th class="">Title</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in scr_course" :key="index">
                                                        <td class="text-center"><a href="javascript:void(0)" @click="removeScrCourse(index)"><i class="fas fa-times text-danger"></i></a></td>
                                                        <td class="text-center"><img :src="item.banner" alt="image" class="w-100"></td>
                                                        <td>{{ item.name }}</td>
                                                    </tr>
                                                    <tr v-if="scr_course.length == 0">
                                                        <td colspan="3" class="text-center">Smart Course Recommendation Empty!</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-12 form-group text-right my-4">
                                        <router-link :to="{name: 'BlogAdmin'}" class="btn btn-outline-warning mr-2" value="Submit">Cancel</router-link>
                                        <input type="Submit" class="btn btn-current" value="Submit">
                                    </div>
                                </form>
                            </div>
                            <div class="col-12 text-center py-3" v-else>
                                <div class="my-3">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import axios from 'axios'
export default {
    name: "Calendar",
    data(){
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            idArticle: this.$route.params.idArticle,
            input: '',
            isLoad: true,
            courses: [],
            scr: [],
            scr_course: [] 
        }
    },
    created(){
        this.getArticle();
    },
    methods: {
        adjustCourseId() {
            this.scr = []
            this.scr_course.map((item) => {
                this.scr.push(item.id)
            })
        },
        setScrCourse() {
            let id = this.input
            let data = this.courses.find(s => s.id == id)
            if(data) {
                this.scr_course.push(data)
            }
            this.input = ''
        },
        removeScrCourse(index) {
            this.scr_course.splice(index, 1)
        },
        async getCourse(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/simple-course`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.courses = res.data.data
                this.isLoad = false
            })
        },
        async getArticle(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/blog/scr/get-by-article-id/${this.idArticle}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                if(res.data.data.length) {
                    res.data.data.map((item) => {
                        item.id = item.course_id
                    })
                }
                this.scr_course = res.data.data
                this.getCourse();
            })
        },
        async postSCR(){
            let data = new FormData();
            data.append('article_id', this.idArticle);
            this.scr.map((item, index) => {
                data.append(`course_ids[${index}]`, item);
            })
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/blog/scr/create`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                        toast: true,
                        title: 'Article',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                })
                this.$router.push({ name: 'BlogAdmin' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Arrticle',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    },
    watch: {
        scr_course:function() {
            this.adjustCourseId()
        }
    }
}
</script>
